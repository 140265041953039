import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent{
  isModalHidden = false;
  constructor(
    private modalService: NgxSmartModalService,
    private router: Router,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.openModal();
    }, 2000); // Open modal on page load after a slight delay

    // Listen for when the modal is completely closed to add the hide class
    this.modalService.getModal('popupModal').onCloseFinished.subscribe(() => {
      this.isModalHidden = true;
    });
  }

  openModal(): void {
    // Remove the hide-modal class before opening the modal
    this.isModalHidden = false;
    this.modalService.getModal('popupModal').open();
  }

  closeModal(): void {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    this.modalService.getModal('popupModal').close();
  }

  navigateToSection(): void {
    this.closeModal();
    this.router.navigate(['/data-insight']); // Adjust route as needed
  }

  private addHideClass(): void {
    const modalElement = document.querySelector('.nsm-dialog');
    if (modalElement) {
      console.log('adding hide-modal class')
      this.renderer.addClass(modalElement, 'hide-modal');
    }
  }
}
