import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/layout/header/header.component";
import { FooterComponent } from "./components/layout/footer/footer.component";
import { AboutAreaComponent } from "./components/about-area/about-area.component";
import { PartnerAreaComponent } from "./components/partner-area/partner-area.component";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { PricingPlanComponent } from "./components/pricing-plan/pricing-plan.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { NotFoundComponent } from "./components/pages/not-found/not-found.component";
import { HomeFourComponent } from "./components/pages/home-four/home-four.component";
import { DynamicTabsComponent } from "./components/dynamic-tabs/dynamic-tabs.component";
import { FeaturesComponent } from "./components/features/features.component";
import { HowItWorksComponent } from "./components/how-it-works/how-it-works.component";
import { AiApplicationsComponent } from "./components/ai-applications/ai-applications.component";
import { ResourcesComponent } from "./components/resources/resources.component";
import { ThreeDModelComponent } from "./components/three-d-model/three-d-model.component";
import { ThreeDTextComponent } from "./components/three-d-text/three-d-text.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { Blog1Component } from "./components/pages/blog1/blog1.component";
import { Blog2Component } from "./components/pages/blog2/blog2.component";
import { Blog3Component } from "./components/pages/blog3/blog3.component";
import { GetStartedComponent } from "./components/get-started/get-started.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { FreeTrialAreaComponent } from "./components/free-trial-area/free-trial-area.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { InterceptorInterceptor } from "./Service/interceptor.interceptor";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AlertModule } from "@full-fledged/alerts";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { GenerativeAiComponent } from "./components/generative-ai/generative-ai.component";
import { SingleInsightAdvantageComponent } from "./components/single-insight-advantage/single-insight-advantage.component";
import { CharacteristicsComponent } from "./components/characteristics/characteristics.component";
import { PlatformsComponent } from "./components/platforms/platforms.component";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxTypewriterModule } from "ngx-typewriter";
import { IntegrationsComponent } from "./components/integrations/integrations.component";
import { OwlCarousel } from "ngx-owl-carousel";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgImageSliderModule } from "ng-image-slider";
import { ModalPopupComponent } from "./components/modal-popup/modal-popup.component";
import { NgxSmartModalModule } from "ngx-smart-modal";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        AboutAreaComponent,
        PartnerAreaComponent,
        FeedbackComponent,
        PricingPlanComponent,
        AboutComponent,
        NotFoundComponent,
        HomeFourComponent,
        CharacteristicsComponent,
        DynamicTabsComponent,
        GenerativeAiComponent,
        FeaturesComponent,
        HowItWorksComponent,
        AiApplicationsComponent,
        SingleInsightAdvantageComponent,
        PlatformsComponent,
        ResourcesComponent,
        ThreeDModelComponent,
        ThreeDTextComponent,
        BlogDetailsComponent,
        Blog1Component,
        Blog2Component,
        Blog3Component,
        GetStartedComponent,
        ContactComponent,
        FreeTrialAreaComponent,
        IntegrationsComponent,
        ModalPopupComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxUiLoaderModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,

        AlertModule.forRoot({
            maxMessages: 5,
            timeout: 5000,
        }),
        NgxPaginationModule,
        NgxTypewriterModule,
        CarouselModule,
        NgImageSliderModule,
        NgxSmartModalModule.forRoot(),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
