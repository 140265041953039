<!-- Popup Modal -->
<ngx-smart-modal #popupModal identifier="popupModal" 
  [closable]="false"
  [dismissable]="false"
  [ngClass]="{'hide-modal': isModalHidden}"
>
    <div class="popup-container">
      <h2>We Make AI Adoption Simple!</h2>
      <div class="popup-inner-container">
        <div class="left-container">
          <p>Your data holds untapped value. Neuclon helps you turn it into actionable insights <span>-- whether you're AI-ready or just starting out.</span></p>
          <div class="points">
            <p>💡 Struggling to make sense of your data?</p>
            <p>💡 Not sure if AI can help?</p>
            <p>💡 Concerned about security or integration?</p>
          </div>
          <div class="cta-section">
            <p>Talk to Veeresh Devireddy</p>
            <a href="https://calendly.com/veeresh" target="_blank" class="cta-button">📅 Book a Free Consultation</a>
          </div>
        </div>
        <div class="right-container">
          <img src="assets/img/neuclon-1.png" />
          <div class="popup-buttons">
            <!-- <button (click)="navigateToSection()">Read More</button> -->
            <button (click)="closeModal()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
</ngx-smart-modal>
  