<div class="d-home-container">
    <div class="about-content">
        <div class="heading-text-container">
            <h1>User-Customizable Data 
                Analytics and AI Platform for 
                Manufacturers
            </h1>
            <!-- <div>
                <tp-writer class="text-effect" [options]="options"></tp-writer>
                <h1>Data</h1>
            </div> -->
        </div>
        <p class="dhp1">
            Single Insight enables you to access, integrate, model, visualize, and explore data from virtually any source:
            equipment, databases, enterprise software like ERP and MES, external sources, and even Excel – all on a single platform.
        </p>
        <p class="dhp2">
            We make it easy to get the information and insights you need to make data-driven decisions to optimize operations and boost your business.
          </p>

        <div class="dhd1">
            <div>
                <img
                    src="../../../../assets/img/partners/microsoft partner.jpeg"
                    alt=""
                />
        
                <a
                    href="https://aws.amazon.com/startups/showcase/startup-details/55ac1070-c0f7-427f-ac01-eea6b6e5cb6f"
                    target="_blank"
                >
                    <img
                        src="../../../../assets/img/partners/AWS-partner-network-white.jpg"
                        alt=""
                    />
                </a>
                <a
                    href="https://www.connstep.org/news-media/media/driving-innovation-connstep-and-neuclon-partner-to-connect-manufacturers-to-custom-enterprise-ai-applications/"
                    target="_blank"
                >
                    <img
                        src="../../../../assets/img/partners/connstep.jpg"
                        alt=""
                    />
                </a>
            </div>
            <p>We partner with world leaders in Security, AI, and Reliability…</p>
        </div>
    </div>
    <div class="d-home-video-container">
        <div class="d-home-video">
            <video autoplay loop controls width="100%" height="100%">
                <source src="../../../../assets/videos/new video.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
        <div class="d-world-manufacturing">
            <h3>Save Money, Time, and Labor
                Unlock your Full Potential</h3>
        </div>
    </div>
</div>
<!-- <div class="platform-highlight">
    <h2>Single Insight is a User-Customizable Data Analytics and AI Platform for Manufacturers</h2>
    <p>
      Single Insight enables you to access, integrate, model, visualize, and explore data from virtually any source:
      equipment, databases, enterprise software like ERP and MES, external sources, and even Excel – all on a single platform.
    </p>
    <p>
      We make it easy to get the information and insights you need to make data-driven decisions to optimize operations and boost your business.
    </p>
  </div>

<div class="d-home-s3-container">
    <div class="d-home-s3-video-container">
        <video autoplay loop [muted]="'muted'" width="100%" height="100%">
            <source src="../../../../assets/videos/new video.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
         <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/oUqRXU_AocY?autoplay=1&loop=1&mute=1&playlist=oUqRXU_AocY"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
    </div>
    
      
    <div class="d-home-s3-partners">
        <p class="s3p1">We partner with world leaders in Security, AI, and Reliability…</p>
        <div class="s3d1">
            <img
                src="../../../../assets/img/partners/microsoft partner.jpeg"
                alt=""
            />
    
            <a
                href="https://aws.amazon.com/startups/showcase/startup-details/55ac1070-c0f7-427f-ac01-eea6b6e5cb6f"
                target="_blank"
            >
                <img
                    src="../../../../assets/img/partners/AWS-partner-network-white.jpg"
                    alt=""
                />
            </a>
        </div>

        <p class="s3p2">We give you the power of Microsoft’s Azure Artificial Intelligence, trained on your company’s data and exclusive to you.        </p>
        
    </div>
</div> -->

<div class="d-home-s4-container">
    <div class="heading-container">
        <h1>Manufacturing is Tough, but getting more out of your Data doesn’t have to be.</h1>
        <p>At Single Insight, we’re all about providing Solutions that give you a Competitive Edge….</p>
    </div>
    
    <div class="grid-container">
        <div class="grid-item" *ngFor="let item of content">
          <h2>{{ item.title }}</h2>
          <p>{{ item.description }}</p>
          <a *ngIf="item.link" href="{{ item.link }}" target="_blank" class="link-button">{{ item.linkText }}</a>
        </div>
    </div>
</div>
  
<div id="threedModel">
    <app-three-d-model></app-three-d-model>
</div>

<div class="benefits-section">
    <h2>With Single Insight, you’ll gain the advantages of…</h2>
    <div class="benefits-grid">
      <div class="benefit-card">
        <strong>Real time / 360° Visibility</strong>
        <p>Be aware of what’s going on, tackle problems, and capture opportunities as they happen, not hours or days later.</p>
      </div>
      <div class="benefit-card">
        <strong>Better Informed Decisions</strong>
        <p>Make decisions with confidence, knowing you have the data to back them up.</p>
      </div>
      <div class="benefit-card">
        <strong>Higher Productivity / Lower Cost</strong>
        <p>Minimize the non-value-added labor of manually collecting, integrating, and analyzing data; instead, free-up your team to do more productive tasks like solving problems and improving productivity.</p>
      </div>
      <div class="benefit-card">
        <strong>Reduced Errors</strong>
        <p>We all know that processing data manually, even with spreadsheets, is prone to error; automation minimizes that risk.</p>
      </div>
      <div class="benefit-card">
        <strong>Captured Opportunities</strong>
        <p>With better information and insights, you’re more likely to discover and capture opportunities you may not have even known existed.</p>
      </div>
      <div class="benefit-card">
        <strong>Competitive Advantage</strong>
        <p>By making better use of your data (and your team) to improve operations and productivity, your competition will have a hard time keeping up.</p>
      </div>
    </div>
  </div>
  
  
<!-- <div>
    <app-characteristics></app-characteristics>
</div> -->

<!-- <div>
    <app-three-d-text></app-three-d-text>
</div> -->

<div id="Use-Case">
    <app-dynamic-tabs></app-dynamic-tabs>
</div>

<div>
    <app-generative-ai></app-generative-ai>
</div>

<!-- <div>
    <app-integrations></app-integrations>
</div> -->

<div id="ai-applications">
    <app-ai-applications></app-ai-applications>
</div>

<div id="Features">
    <app-features></app-features>
</div>

<div id="SingleInsightAdvangate">
    <app-single-insight-advantage></app-single-insight-advantage>
</div>

<!-- <div>
    <app-platforms></app-platforms>
</div> -->

<!-- Start Blog Area -->
<div id="Resources">
    <app-resources></app-resources>
</div>
<!-- End Blog Area -->

<app-free-trial-area></app-free-trial-area>
