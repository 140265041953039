<!-- Start Navbar Area -->
<div
    class="{{ navClass }}"
    [ngClass]="{
        'p-relative': router?.url === '/home-5' || router?.url === '/home-8'
    }"
>
    <div class="kirin-nav">
        <div class="{{ navContainer }}">
            <nav class="navbar navbar-expand-md navbar-light">
                <div class="navbar-brand">
                    <a  routerLink="/">
                        <img
                            src="assets/img/logo/SingleInsight_v2.svg"
                            class="main-logo header-logo"
                            alt="logo"
                        />
                        <img
                            src="assets/img/logo/SingleInsight_v2.svg"
                            class="optional-logo header-logo"
                            alt="logo"
                        />
                    </a>
                    <a href="https://neuclon.ai" target="_blank">
                        <p>A subsidiary of Neuclon Corporation</p>
                    </a>
                </div>

                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div
                    class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown pages-dropdown">
                            <a
                                routerLink="/"
                                (click)="scrollToSection('Home')"
                                class="nav-link"
                            >
                                Home
                            </a>
                        </li>

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/about"
                                routerLinkActive="active"
                                class="nav-link"
                                >About Us</a
                            >
                        </li>

                        <li
                            class="nav-item pointer"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                (click)="scrollToSection('Use-Case')"
                                routerLinkActive="active"
                                class="nav-link"
                                >Use Cases</a
                            >
                        </li>

                        <li
                            class="nav-item pointer"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                (click)="scrollToSection('Features')"
                                routerLinkActive="active"
                                class="nav-link"
                                >Features</a
                            >
                        </li>

                        <!-- <li
                            class="nav-item pointer"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                (click)="scrollToSection('AI-Apps')"
                                routerLinkActive="active"
                                class="nav-link"
                                >Platforms</a
                            >
                        </li> -->

                        <!-- <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/pricing"
                                routerLinkActive="active"
                                class="nav-link"
                                >Pricing</a
                            >
                        </li> -->

                        <li
                            class="nav-item"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                        >
                            <a
                                routerLink="/contact"
                                routerLinkActive="active"
                                class="nav-link"
                                >Contact</a
                            >
                        </li>

                        <!-- <li class="nav-item dropdown pages-dropdown pointer">
                            <a
                                (click)="scrollToSection('Resources')"
                                class="nav-link dropdown-toggle"
                                data-bs-toggle="dropdown"
                            >
                                Resources <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li
                                    class="nav-item"
                                    data-bs-toggle="collapse"
                                    data-bs-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >Blog</a
                                    >
                                </li>

                                <li
                                    class="nav-item"
                                    data-bs-toggle="collapse"
                                    data-bs-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >Webinar</a
                                    >
                                </li>

                                <li
                                    class="nav-item"
                                    data-bs-toggle="collapse"
                                    data-bs-target=".navbar-collapse.show"
                                >
                                    <a
                                        routerLink="/"
                                        routerLinkActive="active"
                                        class="nav-link"
                                        >SI University</a
                                    >
                                </li>
                            </ul>
                        </li> -->
                    </ul>

                    <div class="others-options">
                        <!-- <div class="option-item">
                            <i class="search-btn flaticon-search"></i>
                            <i class="close-btn fas fa-times"></i>
                            <div class="search-overlay search-popup">
                                <div class="search-box">
                                    <form class="search-form">
                                        <input
                                            class="search-input"
                                            name="search"
                                            placeholder="Search"
                                            type="text"
                                        />
                                        <button
                                            class="search-button"
                                            type="submit"
                                        >
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div> -->

                        <a routerLink="/contact" class="btn btn-primary"
                            >Request Demo</a
                        >
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
